import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService }     from '@omnilnp/services/language.service';

@Pipe({
  name: 'initcase',
  pure: false,
})
export class InitCase implements PipeTransform {
  transform(value: string = ''): string {
    if (value != '') {
      let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
    } else {
      return value;
    }
  }
}

@Pipe({
  name: 'filterhidden'
})
export class FilterHidden implements PipeTransform {
  transform( arr: any[] ) {
    return arr.filter(value => {
      if ( value['value']["type"] != 'hidden') {
        return value;
      } else {
      }
    });
  }
}

@Pipe({
  name: 'filterdisplay'
})
export class FilterDisplay implements PipeTransform {
  transform( arr: any[] ) {
    return arr.filter(value => {
      if ( value['value']["type"] != 'display') {
        return value;
      } else {
      }
    });
  }
}

@Pipe({
  name: 'sortcountries',
  pure: false
})
export class SortCountries implements PipeTransform {
  constructor( public language: LanguageService ) {}

  transform( arr: any[] ) {
    return arr.filter( value => { return value } ).sort( (a, b) => { return a['value'][this.language.currentLanguage] > b['value'][this.language.currentLanguage] ? 1 : -1; } );
  }
}
