import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptService }          from '@omnilib/services/encrypt.service';
import { LanguageService }         from '@omnilnp/services/language.service';
import { RegisterService }         from '@omnilnp/services/register.service';
import { RouterService }           from '@omnilnp/services/router.service';
import { DataService as d }             from '@omnilib/services/data.service';
import { environment }             from '@omnienvironments/omnilnp/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  headers: HttpHeaders = new HttpHeaders;
  deviceInfo: string = '';

  constructor ( public encrypt:  EncryptService
              , public http:     HttpClient
              , public language: LanguageService
              , public register: RegisterService
              , public router:   RouterService
              , public data: d
              ) {}
  
  async deleteTempLibMember ( $filename ) {
    await this.data.callapi('delete', 'temp-lib-member/order-id/' + $filename, null);
  }

  async getTempLibMember( $filename ) {
    let data = await this.data.callapi('get', 'temp-lib-member/order-id/' + $filename, null);
    return data['result']['resultset'];
  }

  async saveTempLibMember( $filename ) {
    let content = {};

    content['formitems'] = {};

    for ( let item in this.register.formitems ) {
      if ( this.register.formitems[item].value != '' ) {
        content['formitems'][item] = this.register.formitems[item].value;
      }
    }

    content['dutchaddress'] = {};

    for ( let item in this.register.dutchaddress ) {
      if ( this.register.dutchaddress[item].value != '' ) {
        content['dutchaddress'][item] = this.register.formitems[item].value;
      }
    }

    content['language'] = this.language.currentLanguage;

    let url = environment.production ? environment.apiurlWsdl : 'wsdl';
    if(environment.acc) {
      url = environment.apiurlWsdl;
    }

    let jsonParams = {  'data'    : JSON.stringify(content),
                        'orderId' : $filename 
                      }
    console.log(jsonParams);
                      

    await this.data.callapi('put', 'temp-lib-member' , jsonParams);

  }

  async savedata( $filename ) {

    let content = {};

    content['formitems'] = {};

    for ( let item in this.register.formitems ) {
      if ( this.register.formitems[item].value != '' ) {
        content['formitems'][item] = this.register.formitems[item].value;
      }
    }

    content['dutchaddress'] = {};

    for ( let item in this.register.dutchaddress ) {
      if ( this.register.dutchaddress[item].value != '' ) {
        content['dutchaddress'][item] = this.register.formitems[item].value;
      }
    }

    content['language'] = this.language.currentLanguage;

    let url = environment.production ? environment.apiurlWsdl : 'wsdl';
    if(environment.acc) {
      url = environment.apiurlWsdl;
    }

    let jsonParams = { 'message' : JSON.stringify(content)
                     , 'file'    : $filename
                     , 'what'    : 'savedata'
                     }

    let params = await this.encrypt.encrypt(jsonParams);

    try {
      this.headers.set('Access-Control-Allow-Origin', '*');
      await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
    } catch(e) {
    }
  }

  async saverenewaldata( $filename, $borrower ) {
    let url = environment.production ? environment.apiurlWsdl : 'wsdl';

    let jsonParams = { 'message' : JSON.stringify($borrower)
                     , 'file'    : $filename
                     , 'what'    : 'savedata'
                     }

    let params = await this.encrypt.encrypt(jsonParams);

    try {
      this.headers.set('Access-Control-Allow-Origin', '*');
      await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
    } catch(e) {
    }
  }

  async getdata( $filename: string ) {
    let url        = ( environment.production ? environment.apiurlWsdl : 'wsdl' );
    let jsonParams = { 'what' : 'getdata'
                     , 'file' : $filename
                     };
    let params     = await this.encrypt.encrypt(jsonParams);
    
    try {
      let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return await JSON.parse ( await this.encrypt.decrypt ( result ) );
    } catch(e) {
    }
  }

  async deletedata( $filename: string ) {
    let url        = ( environment.production ? environment.apiurlWsdl : 'wsdl' );
    let jsonParams = { 'what' : 'deletedata'
                     , 'file' : $filename
                     };
    let params     = await this.encrypt.encrypt(jsonParams);

    try {
      let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return await JSON.parse ( await this.encrypt.decrypt ( result ) );
    } catch(e) {
    }
  }

}
