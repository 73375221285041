import { Component, OnInit } from '@angular/core';
import { IconsService } from '@omnilnp/services/icons.service';
import { LanguageService } from '@omnilnp/services/language.service';
import { RouterService } from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  constructor(
    public icons: IconsService,
    public language: LanguageService,
    public router: RouterService
  ) {}

  ngOnInit() {}

  onSubmit() {}
}
