import { Injectable }      from '@angular/core';
import { DataService }     from '@omnilib/services/data.service';
import { LanguageService } from '@omnilnp/services/language.service';
import { ListService }     from '@omnilib/services/list.service';
import { RegisterService } from '@omnilnp/services/register.service';
import { RouterService }   from '@omnilnp/services/router.service';
import LibraryCardTypes    from '@omnilnp/assets/json/librarycardtypes.json';


@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  error: boolean = false;

  constructor(
    public data:     DataService,
    public language: LanguageService,
    public list:     ListService,
    public register: RegisterService,
    public router:   RouterService
  ) {}

  async load() {
    this.router.showwait = true;
    await this.getcountries();
    await this.getmembertypes();
    this.router.showwait = false;
  }

  async getcountries() {
    let countries = await this.data.callapi('get', 'country', null);

//     let countries = await this.data.getcountries();
    for ( let i in countries['result']['resultset'] ) {
      let country = {};
      country['code']  = countries['result']['resultset'][i]['iso3166Alpha2'];
      country['code2'] = countries['result']['resultset'][i]['iso3166Alpha3'];
      country['en']    = countries['result']['resultset'][i]['nameEng'];
      country['nl']    = countries['result']['resultset'][i]['nameNed'];
      this.router.countries[country['code2']] = country;
    }

    console.log ( this.router.countries );
  }

  async getmembertypes() {
    this.router.membertypes = LibraryCardTypes;
    console.log ( this.router.membertypes );
  }
}
