import { Injectable }  from '@angular/core';
import { DataService } from '@omnilib/services/data.service';
import { EncryptService } from '@omnilib/services/encrypt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { XMLService } from '@omnilib/services/xml.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@omnienvironments/omnicms/devcms/environment';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor ( public  data: DataService
              , public  encrypt: EncryptService
              , public  http: HttpClient
              , public  xml: XMLService
              ) {}

  headers: HttpHeaders = new HttpHeaders;

  async executeOperation( name, jsonParams, format = '', reportname = '', filetype = '', filecontent = '' ): Promise<any> {
    let result = {};

    if ( environment.apiurl != '' ) {
      result['Result'] = await this.callapi ( name, jsonParams, format, reportname, filetype, filecontent );
    } else {
      result['Result'] = await this.callendpoint ( name, jsonParams, format, reportname, filetype, filecontent );
    }

    return result;
  }

  async callapi( name, inParams, format = '', reportname = '', filetype = '', filecontent = '' ) {
    let what = ( name == 'operation' || name == '' ) ? 'ExecuteOperation' : name;

    let bota = what == 'UploadPhotos';

    let xml = this.xml.createXML( what, this.createBody( inParams, reportname, filetype, filecontent ), bota );

    let url = environment.production ? environment.apiurl : 'wsdl';

    /*
    let jsonParams = { 'url'  : inParams['Endpoint']
                     , 'xml'  : xml
                     , 'what' : 'soapcall'
                   };
    */

    let jsonParams = { 'xml'  : xml
                     , 'what' : 'soapcall'
                     };

    let params = await this.encrypt.encrypt(jsonParams);

    try {
      let result = this.data.callapi( 'post', 'soap', jsonParams );
      //let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return await this.getResults ( what, await this.xml.xmlStringToJson ( await this.encrypt.decrypt ( result ), true ), true );
    } catch(e) {
      console.log (e);
      return this.noconnection();
    }


  }

  async callendpoint(name, inParams, format = '', reportname = '', filetype = '', filecontent = '' ) {
    let what = ( name == 'operation' || name == '' ) ? 'ExecuteOperation' : name;

    if ( environment.apiurl == '' ) {

      let bota = what == 'UploadPhotos'

      let xml = this.xml.createXML(what, this.createBody( inParams, reportname, filetype, filecontent ), bota );
      try {
        let result = await this.http.post( inParams['Endpoint'], xml, { responseType: 'text' } ).toPromise();
        return await this.getResults ( what, await this.xml.xmlStringToJson( result ) );
      } catch(e) {
        return this.noconnection();
      }
    } else {
      return this.noconnection();
    }
  }

  noconnection() {
    let result = {};
    result['Result']                = {};
    result['Result']['Code']        = '999';
    result['Result']['Description'] = 'No connection possible';
    return result;
  }

  createBody( $inParams, $reportname = '', $filetype = '', $filecontent = '' ) {
    let body = {};

    if ( typeof $inParams != 'undefined' ) {
      for ( let param in $inParams ) {
        switch (param) {
          case 'Operation' :
            body [ param ] = this.createSoapRequest( $inParams[param], $inParams['Fields'] );
            break;
          case 'Fields' :
          case 'Endpoint' :
            break;
          default :
            body [ param ] = $inParams[param];
            break;
        }
      }
    }

    if ( $reportname != '' ) {
       body['ReportName'] = $reportname;
    }

    if ( $filetype != '' ) {
       body['FileType'] = $filetype;
    }

    if ( $filecontent != '' ) {
      body['FileContent'] = $filecontent;
    }

    return body;
  }

  createSoapRequest($operation, $fields) {
    return '<CMSRequest><Operation NAME="'+$operation+'">'+this.createFields($fields)+'</Operation></CMSRequest>';
  }

  createFields($fields) {
    let fields: string = '';

    if ( typeof $fields != 'undefined' && $fields.length > 0 ) {
      for( let field of $fields ) {
        fields += '<'+field['name']+'><![CDATA['+field['value']+']]></'+field['name']+'>';
      }
    }

    return fields;
  }

  getResults($what, $operationResult, $soapcall = false){
    let result = {};
    let emptyResult = {};

    try {
      if ( $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['SOAP-ENV:Fault'] ) {
        result['Code']        = '999';
        result['Description'] = 'Unexpected error';
        return result;
      }
    } catch(e) {
      // do nothing
    }

    try {
      if ( $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:ResultObject'] ) {
        result['Code']        = $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:ResultObject']['Code']['Value'];
        result['Description'] = $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:ResultObject']['Description']['Value'];
      }
    } catch(e) {
      // do nothing
    }

    try {
      if ( $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:TResultObject'] ) {
        result['Code']        = $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:TResultObject']['Code']['Value'];
        result['Description'] = $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:TResultObject']['Description']['Value'];
      }
    } catch(e) {
      // do nothing
    }

    try {
      if ( $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:TExecuteObject']['ExecuteData']['Value'] ) {
        let data = this.xml.xmlStringToJson($operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:TExecuteObject']['ExecuteData']['Value'], $soapcall);

        if ( data['CMSResponse']['ResultSets']['ResultSet'] ) {
          result['ResultSets'] = data['CMSResponse']['ResultSets']['ResultSet'];
          //return result;
        }
      }
    } catch(e) {
      // do nothing
    }

    try {
      if ( $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:FileObject']['FileContent'] ) {
        result['Content']     = $operationResult['SOAP-ENV:Envelope']['SOAP-ENV:Body']['NS1:'+$what+'Response']['NS2:FileObject']['FileContent'];
      }
    } catch(e) {
      // do nothing
    }

    if ( result == emptyResult ) {
      result['Code']        = '999';
      result['Description'] = 'No results';
    }

    return result;
  }

}
