import { Injectable }      from '@angular/core';
import { DataService }     from '@omnilnp/services/data.service';
import { EncryptService }  from '@omnilib/services/encrypt.service';
import { LanguageService } from '@omnilnp/services/language.service';
import { ListService }     from '@omnilib/services/list.service';
import { RegisterService } from '@omnilnp/services/register.service';
import { RenewalService }  from '@omnilnp/services/renewal.service';
import { RouterService }   from '@omnilnp/services/router.service';
import { format, toDate  } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PaymentService  {

  ingenico          = {};
  url:       string = '';
  amount:    number = 0;

  constructor( public data:     DataService
             , public encrypt:  EncryptService
             , public language: LanguageService
             , public list:     ListService
             , public register: RegisterService
             , public renewal:  RenewalService
             , public router:   RouterService
             ) {
  }

  async getSign( $fields, $sign ) {
    let shastring: string = '';

    Object.keys($fields).sort().forEach(function(key) {
      if ( $fields[key] != '' ) {
        shastring += `${key}=${$fields[key]}${$sign}`;
      }
    });

    return await this.encrypt.shasign( shastring );
  }

  getLanguagueCode( $lang ){
    switch($lang) {
      case 'eng' :
      case 'en'  :
        return 'en_US';
        break;
      case 'nl'  :
      case 'nld' :
      case 'nl2' :
      default    :
        return 'nl_NL';
        break;
    }
  }

  async initialize() {
    console.log("initialising");
    let shasign: string  = '';
    this.url      = this.router.config['ingenico']['url'];

    if ( this.router.title == 'register' ) {

      this.amount = 0;
      let memberType = await this.register.getBorrowerType(this.register.formitems.membertype.value);
      console.log(memberType);
      if ( memberType['priceInEur'] != undefined
      && memberType['priceInEur'] != '0' ) {
          console.log("Changing price amount")
        this.amount = memberType['priceInEur'] * 100;
      }

      if ( this.amount == 0 ) {
        console.log("amount is,", this.amount);
        return;
      }

      this.ingenico = {
        'PSPID'          : this.router.config['ingenico']['pspid'],
        'ORDERID'        : this.createOrderID(),
        'AMOUNT'         : this.amount,
        'CURRENCY'       : this.router.config['ingenico']['currency'],
        'LANGUAGE'       : this.getLanguagueCode( this.language.currentLanguage ),
        'CN'             : ( this.register.formitems.initials.value + ' ' + this.register.formitems.middlename.value + ' ' + this.register.formitems.lastname.value ).replace('  ', ' '),
        'EMAIL'          : this.register.formitems.emailaddress.value,
        'OWNERZIP'       : this.register.formitems.zipcode.value,
        'OWNERADDRESS'   : ( this.register.formitems.street.value + ' ' + this.register.formitems.housenumber.value + ' ' + this.register.formitems.housenumber_suffix.value ).trim(),
        'OWNERCTY'       : this.register.formitems.city.value,
        'OWNERTOWN'      : this.register.formitems.city.value,
        'COM'            : "",
        'TITLE'          : this.language.getTranslation('payment.requestnew'),
        'BGCOLOR'        : this.router.config['ingenico']['bgcolor'],
        'TXTCOLOR'       : this.router.config['ingenico']['txtcolor'],
        'TBLBGCOLOR'     : this.router.config['ingenico']['tblbgcolor'],
        'TBLTXTCOLOR'    : this.router.config['ingenico']['tbltxtcolor'],
        'BUTTONBGCOLOR'  : this.router.config['ingenico']['buttonbgcolor'],
        'BUTTONTXTCOLOR' : this.router.config['ingenico']['buttontxtcolor'],
        'FONTTYPE'       : this.router.config['ingenico']['fonttype'],
        'PM'             : this.router.config['ingenico']['pm'],
        'HOMEURL'        : this.router.config['ingenico']['homeurl'],
        'ACCEPTURL'      : this.router.config['ingenico']['accepturl'],
        'DECLINEURL'     : this.router.config['ingenico']['declineurl'],
        'EXCEPTIONURL'   : this.router.config['ingenico']['exceptionurl'],
        'CANCELURL'      : this.router.config['ingenico']['cancelurl'],
        'SHASIGN'        : ''
      };
    } else {
      this.ingenico = {
        'PSPID'          : this.router.config['ingenico']['pspid'],
        'ORDERID'        : this.createOrderID(),
        'AMOUNT'         : this.list.getValue( this.renewal.borrower, 'Price_in_eur', false ),
        'CURRENCY'       : this.router.config['ingenico']['currency'],
        'LANGUAGE'       : this.getLanguagueCode( this.language.currentLanguage ),
        'CN'             : ( this.list.getValue( this.renewal.borrower, 'Initials', true ) + ' ' + this.list.getValue( this.renewal.borrower, 'Lastnameprefix', true ) + ' ' + this.list.getValue( this.renewal.borrower, 'Lastname', true ) ).replace('  ', ' '),
        'EMAIL'          : '',
        'OWNERZIP'       : '',
        'OWNERADDRESS'   : '',
        'OWNERCTY'       : '',
        'OWNERTOWN'      : '',
        'COM'            : '',
        'TITLE'          : this.language.getTranslation('payment.renewal'),
        'BGCOLOR'        : this.router.config['ingenico']['bgcolor'],
        'TXTCOLOR'       : this.router.config['ingenico']['txtcolor'],
        'TBLBGCOLOR'     : this.router.config['ingenico']['tblbgcolor'],
        'TBLTXTCOLOR'    : this.router.config['ingenico']['tbltxtcolor'],
        'BUTTONBGCOLOR'  : this.router.config['ingenico']['buttonbgcolor'],
        'BUTTONTXTCOLOR' : this.router.config['ingenico']['buttontxtcolor'],
        'FONTTYPE'       : this.router.config['ingenico']['fonttype'],
        'PM'             : this.router.config['ingenico']['pm'],
        'HOMEURL'        : this.router.config['ingenico']['homeurl'],
        'ACCEPTURL'      : this.router.config['ingenico']['accepturl'],
        'DECLINEURL'     : this.router.config['ingenico']['declineurl'],
        'EXCEPTIONURL'   : this.router.config['ingenico']['exceptionurl'],
        'CANCELURL'      : this.router.config['ingenico']['cancelurl'],
        'SHASIGN'        : ''
      };
    }

    shasign = await this.getSign( this.ingenico, this.router.config['ingenico']['sha_in_key'] );

    this.ingenico['SHASIGN'] = shasign;
  }

  createOrderID() {
    return ( this.router.title == 'renewal' ? 'Renew' : '' )
         + 'CARD_'
         + this.router.config['ingenico']['orderid']
         + '_'
         + format(toDate(new Date()),'ddMMyyyyHHmmss')
    ;
  }

  async checkSignOut( $fields, $sign ) {
    let shasign:   string = '';
    let shastring: string = '';

    let cleanfields = {};

    Object.keys($fields).forEach(function(key) {
     cleanfields[decodeURIComponent(key).toUpperCase()] = decodeURIComponent($fields[key]);
    });

    Object.keys(cleanfields).sort().forEach(function(key) {
      if ( key == 'SHASIGN' ) {
        shasign = cleanfields[key];
      } else if ( cleanfields[key] != '' ) {
        shastring += `${key.toUpperCase()}=${cleanfields[key]}${$sign}`;
      }
    });

    //this.log.log ( 'checksign ingenico ' + shasign, 3 );
    //this.log.log ( 'checksign omnipas ' + await this.encrypt.shasign(shastring), 3 );

    return shasign == await this.encrypt.shasign(shastring);
  }

}
