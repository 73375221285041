import { Component, OnInit } from '@angular/core';
import { IconsService }      from '@omnilnp/services/icons.service';
import { RegisterService }   from '@omnilnp/services/register.service';
import { RouterService }     from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-dutchaddress',
  templateUrl: './dutchaddress.component.html',
})
export class DutchAddressComponent implements OnInit {

  addressloading: boolean = false;

  constructor ( public icons:    IconsService
              , public register: RegisterService
              , public router:   RouterService
              ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.router.showwait = false;
  }

  orderasis(a: any, b: any) {
    return 1;
  }

  keyup( key, value ) {
    if ( key == 'zipcode' ) {
      if ( value.length == 4 ) {
        this.register.dutchaddress.zipcode.value = value+' ';
      } else {
        this.register.dutchaddress.zipcode.value = value.toUpperCase();
      }
      return;
    }
  }

  async checkinput( key, value ) {
    if ( ( key == 'zipcode' || key == 'housenumber' ) ) {
      if (     this.register.dutchaddress.zipcode.value.replace(' ','').length == 6
            && this.register.dutchaddress.housenumber.value.replace(' ','').length > 0
         ) {
        this.addressloading = true;
        await this.register.searchAddress ( this.register.dutchaddress );
        this.addressloading = false;
      } else {
        this.register.dutchaddress.street.value      = '';
        this.register.dutchaddress.city.value        = '';
        this.register.dutchaddress.fulladdress.value = '';
      }
      return;
    }

    if ( key == 'housenumber_suffix' ) {
      this.register.dutchaddress.fulladdress.value = this.register.dutchaddress.street.value
                                                   + ' '
                                                   + this.register.dutchaddress.housenumber.value
                                                   + ( this.register.dutchaddress.housenumber_suffix.value != '' ? ' ' + this.register.dutchaddress.housenumber_suffix.value : '' )
                                                   + ', '
                                                   + this.register.dutchaddress.zipcode.value
                                                   + '  '
                                                   + this.register.dutchaddress.city.value
                                                  ;
      return;
    }
  }

  checkrequiredfields( bool = false ) {
    let error = false;

    if ( this.register.dutchaddress.fulladdress.value == '' || this.register.dutchaddress.fulladdress.error != '' ) {
      error = true;
    }

    return error;
  }

  async back() {
    await this.router.navigateto('register');
  }

  async submit() {
    this.router.navigateto('overview');
  }

}
