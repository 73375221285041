import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map, catchError, switchMap, share } from 'rxjs/operators';
import { RouterService } from '@omnilnp/services/router.service';


@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  initialLanguage = 'nl';
  currentLanguage = 'nl';

  reload: boolean = false;

  constructor( public translate: TranslateService
             , public router: RouterService
             ) {}

  initialize() {
    this.currentLanguage = this.initialLanguage;
    this.translate.setDefaultLang(this.currentLanguage);
  }

  async changeLanguage($lang: string, $reload: boolean = true ) {
    if ($lang == '') {
      this.currentLanguage = this.initialLanguage;
    } else {
      this.currentLanguage = $lang.toLowerCase();
      this.reload = $reload;
    }

    this.translate.setDefaultLang(this.currentLanguage);
  }

  getTranslation($text: string, $type: string = 'I'): string {
    let result: string = '';

    this.translate.get($text).subscribe((res: string) => {
      result = res;
    });

    switch ($type) {
      case 'C':
        return result.replace(/\w\S*/g, (w) =>
          w.replace(/^\w/, (c) => c.toUpperCase())
        );
      case 'I':
        return result[0].toUpperCase() + result.slice(1);
        break;
      case 'L':
        return result.toLowerCase();
        break;
      case 'U':
        return result.toUpperCase();
        break;
      default:
        return result;
        break;
    }
  }
}
