import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute }       from '@angular/router';
import { DataService }          from '@omnilnp/services/data.service';
import { IconsService }         from '@omnilnp/services/icons.service';
import { LanguageService }      from '@omnilnp/services/language.service';
import { PaymentService }       from '@omnilnp/services/payment.service';
import { RegisterService }      from '@omnilnp/services/register.service';
import { RenewalService }       from '@omnilnp/services/renewal.service';
import { RouterService }        from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {

  initialized: boolean = false;
  loaded:      boolean = false;

  constructor ( public  route:          ActivatedRoute
              , public  data:           DataService
              , public  icons:          IconsService
              , public  language:       LanguageService
              , public  payment:        PaymentService
              , public  register:       RegisterService
              , public  renewal:        RenewalService
              , public  router:         RouterService
              ) {
  }

  async ngOnInit() {
    this.router.showwait = true;
    this.initialized = false;

    await this.onTimeOut();
  }

  async onTimeOut() {
    setTimeout( async () => {
      if ( typeof this.router.config['ingenico'] != 'undefined' ) {
        this.loaded = true;
        try {
          await this.initialize();
        } catch(e) {
          this.router.success = false;
          this.router.message = 'failed_payment_text';
          this.router.navigateto('result');
        }
        this.initialized = true;
        this.router.showwait = false;
      } else {
        await this.onTimeOut();
      }
    }, 100);
  }

  async initialize() {
    const params      = this.route.params;
    const queryparams = this.route.queryParams;

    if ( params['value']['token1'] ) {

      let result = await decodeURIComponent(params['value']['token1']);
      
      if ( queryparams['value'] ) {

        let checksign     = await this.payment.checkSignOut( queryparams['value'], this.router.config['ingenico']['sha_out_key'] );

        if ( checksign ) {

          let orderID       = queryparams['value']['orderID'];

          this.router.title = orderID.indexOf('Renew') == -1 ? 'register' : 'renewal';
          console.log('Before');
          let data  = JSON.parse(await this.data.getTempLibMember( orderID ));
          console.log('After');
          if ( this.router.title == 'renewal' ) {
            this.renewal.borrower = data;
          } else {
            console.log(data);
            console.log(data['formitems']);
            for ( let item in data['formitems'] ) {
              this.register.formitems[item].value = data['formitems'][item];
            }

            for ( let item in data['dutchaddress'] ) {
              this.register.dutchaddress[item].value = data['dutchaddress'][item]['value'];
            }

            if ( this.language.currentLanguage != data['language'] ) {
              await this.language.changeLanguage( data['language'] );
            }
          }

          if ( result  == 'success' ) {
            if ( this.router.title == 'renewal' ) {
              await this.renewal.save(true);
            } else {
              await this.register.save(true);
            }

            if ( this.router.success ) {
              this.data.deleteTempLibMember( orderID );
            }

            this.router.navigateto('result');

          } else {
            this.router.success = false;
            this.router.message = 'failed_payment_text';
            this.router.navigateto('result');
          }
        } else {
          this.router.success = false;
          this.router.message = 'failed_payment_text';
          this.router.navigateto('result');
        }
      } else {
        this.router.success = false;
        this.router.message = 'failed_payment_text';
        this.router.navigateto('result');
      }
    } else {
      this.router.success = false;
      this.router.message = 'failed_payment_text';
      this.router.navigateto('result');
    }
  }
}
