import { Component, OnInit } from '@angular/core';
import { IconsService }      from '@omnilnp/services/icons.service';
import { LanguageService }   from '@omnilnp/services/language.service';
import { ListService }       from '@omnilib/services/list.service';
import { RegisterService }   from '@omnilnp/services/register.service';
import { RouterService }     from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {

  notarobot:      boolean = false;
  checked:        boolean = false;
  addressloading: boolean = false;

  constructor ( public icons:    IconsService
              , public language: LanguageService
              , public list:     ListService
              , public register: RegisterService
              , public router:   RouterService
              ) {}

  async ngOnInit() {
    this.router.title = 'register';
  }

  orderasis(a: any, b: any) {
    return a;
  }

  order ( a: any, b: any ) {
    return a.value > b.value
  }

  resolved (captchaResponse ) {
    this.notarobot = true;
  }

  errored(error) {
    console.warn(`reCAPTCHA error encountered`);
  }

  checkmembertypeinput( key, value ) {
    console.log (key);
    console.log (value);
  }

  async checkinput( key, value ) {
    if ( key == 'country' ) {
      if ( value != 'NLD' ) {
        this.register.formitems.city.type        = 'text';
        this.register.formitems.city.required    = true;
        this.register.formitems.street.type      = 'text';
        this.register.formitems.street.required  = true;
        this.register.formitems.fulladdress.type = 'hidden';
      } else {
        this.register.formitems.city.type        = 'hidden';
        this.register.formitems.city.required    = false;
        this.register.formitems.street.type      = 'hidden';
        this.register.formitems.street.required  = false;
        this.register.formitems.fulladdress.type = 'display';
      }
      return;
    }

    if ( ( key == 'zipcode' || key == 'housenumber' ) && this.register.formitems.country.value == 'NLD' ) {
      if (     this.register.formitems.zipcode.value.replace(' ','').length == 6
            && this.register.formitems.housenumber.value.replace(' ','').length > 0
         ) {
        this.addressloading = true;
        await this.register.searchAddress ( this.register.formitems );
        this.addressloading = false;
      } else {
        this.register.formitems.street.value      = '';
        this.register.formitems.city.value        = '';
        this.register.formitems.fulladdress.value = '';
      }
      return;
    }

    if ( key == 'housenumber_suffix' && this.register.formitems.country.value == 'NLD' ) {
      this.register.formitems.fulladdress.value = this.register.formitems.street.value
                                                + ' '
                                                + this.register.formitems.housenumber.value
                                                + ( this.register.formitems.housenumber_suffix.value != '' ? ' ' + this.register.formitems.housenumber_suffix.value : '' )
                                                + ', '
                                                + this.register.formitems.zipcode.value
                                                + '  '
                                                + this.register.formitems.city.value
                                                ;
      return;
    }

    if ( key == 'emailaddress' ) {
      let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if ( value == '' || regexp.test(value) ) {
        this.register.formitems[key].error = '';
      } else {
        this.register.formitems[key].error = 'invalid';
      }

      return;
    }

    if ( key == 'phonenumber' ) {
      let regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

      if ( value == '' || regexp.test(value) ) {
        this.register.formitems[key].error = '';
      } else {
        this.register.formitems[key].error = 'invalid';
      }

      return;
    }

  }

  keyup( event, key, value ) {
    if ( key == 'initials') {
      if ( event.key == 'Backspace' ) {
        value = value.replace('.', '').slice(0, -1).toUpperCase()
      } else {
        value = value.replace('.', '').toUpperCase()
      }

      let newvalue = '';

      for (let c of value) {
        if ( c != '.' ) {
          newvalue += c+'.';
        }
      }

      this.register.formitems[key].value = newvalue;

      return;
    }

    if ( key == 'lastname' || key == 'street' ) {
      this.register.formitems[key].value = value.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
      return;
    }

    if ( key == 'city' ) {
      this.register.formitems[key].value = value.toUpperCase();
      return;
    }

    if ( key == 'zipcode' && this.register.formitems.country.value == 'NLD' ) {
      if ( value.length == 4 ) {
        this.register.formitems[key].value = value+' ';
      } else {
        this.register.formitems[key].value = value.toUpperCase();
      }
      return;
    }
  }

  checkrequiredfields( bool = false ) {
    let error = false;
    
    for ( let formitem in this.register.formitems ) {
      if ( ( this.register.formitems[formitem].required && this.register.formitems[formitem].value == '' ) || this.register.formitems[formitem].error != '' ) {
        if ( bool ) {
          this.register.formitems[formitem].error = 'required';
        }
        error = true;
      }
    }

    return error;
  }

  async submit() {
    this.router.showwait = true;

    if ( this.checkrequiredfields( true ) ) {
      return;
    }

    let result = await this.register.checkuniquelibmember();

    if ( !result ) {
      this.router.success = false;
      this.router.message = 'failed_unique_text';
      this.router.navigateto('result');
      return;
    }

    if ( this.register.formitems.country.value != 'NLD' ) {
      this.router.navigateto('dutchaddress');
    } else {
      this.router.navigateto('overview');
    }
  }


  getoptionvalue( option ) {
    return option['value']['code'];
  }

  getoptiontext( membertype ) {
    if (    membertype.value                != undefined
         && membertype.options              != undefined
         && membertype.options.free         != undefined
         && membertype.options.free.options != undefined
         && membertype.options.paid         != undefined
         && membertype.options.paid.options != undefined
        ) {
      return membertype.options.free.options[membertype.value] ?? membertype.options.paid.options[membertype.value];
    } else {
      return '';
    }
  }

}
