import { Injectable }       from '@angular/core';
import { LanguageService }  from '@omnilnp/services/language.service';
import { ListService }      from '@omnilib/services/list.service';
import { OperationService } from '@omnilib/services/operation.service';
import { RouterService }    from '@omnilnp/services/router.service';
import { add, parse, format, toDate  }  from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class RenewalService {

  borrower;

  constructor(
    public language: LanguageService,
    public list: ListService,
//    public mail: MailService,
    public operation: OperationService,
    public router: RouterService
  ) {}

  initialize() {
  }

  async onTimeOut() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    let endpoint = await this.list.getValue ( this.router.config, 'Endpoint', false );
    let token    = await this.list.getValue ( this.router.config, 'Token', false );

    if ( endpoint == '' || token == '' ) {
      await delay(100);
      return await this.onTimeOut();
    } else {
      return;
    }
  }

  async getBorrower( id ) {
    await this.onTimeOut();

    let jsonParams = {
      Endpoint: this.router.config['Endpoint'],
      Token: this.router.config['Token'],
      Operation: 'readLibMemberData',
      Fields: [ { name: 'CardholderID', value: id }]
    };

    let result = await this.operation.executeOperation('operation', jsonParams);

    console.log ( result );

    if (    result['Result']['Code']
         && result['Result']['Code'] == '0'
         && ( !this.list.emptyList( result['Result']['ResultSets'] ) )
         && this.list.getValue( result['Result']['ResultSets'], 'Price_in_eur', true ) != '0'
       ) {
      this.borrower       = result['Result']['ResultSets'];
      this.router.success = true;
      this.router.message = 'success_member_found';
    } else {
      this.borrower       = null;
      this.router.success = false;
      this.router.message = 'failed_member_found';
    }
  }

  async save( paid: boolean = false ) {
    let enddate = format ( add ( parse( this.list.getValue( this.borrower, 'ValidUntil', true ), 'dd-MM-yyyy HH:mm:ss', new Date() ), { years: 1 } ), 'dd-MM-yyyy HH:mm:ss' );
    this.list.setValue( this.borrower, 'ValidUntil', format( parse(enddate, 'dd-MM-yyyy HH:mm:ss', new Date() ), 'dd-MM-yyyy' ) );

    let jsonParams = {
      Endpoint: this.router.config['Endpoint'],
      Token: this.router.config['Token'],
      Operation: 'updateCardEndDate',
      Fields: [ { name: 'CardholderID'      , value: this.list.getValue( this.borrower, 'CardholderID', true     ) }
              , { name: 'Cardnumber'        , value: this.list.getValue( this.borrower, 'CardholderNumber', true ) }
              , { name: 'EndDate'           , value: enddate }
              ]
    };

    let result = await this.operation.executeOperation('operation', jsonParams);

    if (result['Result']['Code']) {
      if (result['Result']['Code'] == '0') {
        this.router.success = true;
        this.router.message = 'success_renewal_text';
      } else {
        this.router.success = false;
        this.router.message = paid ? 'failed_payment_text' : 'failed_renwal_text';
      }
    } else {
      this.router.success = false;
      this.router.message = paid ? 'failed_payment_text' : 'failed_renwal_text';
    }

    this.initialize();
  }
}
