import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@omnienvironments/omnilnp/dev/environment';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  title:           string  = '';
  showwait:        boolean = false;
  paying:          boolean = false;
  config:          any     = environment.config;

  success:         boolean = false;
  message:         string  = '';

  countries:   any = [];
  membertypes: any = [];

  constructor(public router: Router) {}

  navigateto($link: string = '') {
    this.router.navigate(['/' + $link]);
  }
}
