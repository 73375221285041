import { Component, OnInit } from '@angular/core';
import { IconsService }   from '@omnilnp/services/icons.service';
import { ListService }    from '@omnilib/services/list.service';
import { RenewalService } from '@omnilnp/services/renewal.service';
import { RouterService }  from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit {

  constructor ( public icons:   IconsService
              , public list:    ListService
              , public renewal: RenewalService
              , public router:  RouterService
              ) {}

  ngOnInit( ) {
    this.initialize();
  }

  async initialize() {
    this.router.showwait = false;
  }
}
