import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  HostListener,
} from '@angular/core';
import { LanguageService } from '@omnilnp/services/language.service';
import { LoadingService } from '@omnilnp/services/loading.service';
import { RouterService } from '@omnilnp/services/router.service';

@Component({
  selector: 'omnicard-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    public language: LanguageService,
    public loading: LoadingService,
    public router: RouterService
  ) {}

  async ngOnInit() {
    this.router.showwait = true;
    this.language.initialize();
    await this.loading.load();
  }
}
