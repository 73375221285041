<lenerspas-header></lenerspas-header>
<div
  class="w3-content w3-container w3-white w3-margin-bottom w3-center"
  *ngIf="router.showwait"
>
  <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
</div>

<div class="w3-content w3-white" *ngIf="!router.showwait">
  <div class="w3-container w3-margin-bottom w3-responsive">
    <h2 class="w3-margin-bottom">
      {{ 'register.title' | translate | initcase }}
    </h2>
    <hr class="w3-border-dark-grey" />
    <p>{{ 'register.attention' | translate | initcase }}</p>
    <hr class="w3-border-dark-grey" />
  </div>
  <div class="w3-container w3-margin-bottom w3-responsive">
    <div
      class="w3-cell-row w3-margin-bottom"
      *ngFor="let formitem of register.dutchaddress | keyvalue: orderasis | filterhidden"
    >
      <div class="w3-cell w3-mobile w3-cell-middle col40">
        {{ 'formitems.' + formitem.key | translate | initcase
        }}<span *ngIf="formitem.value.required">*</span>
      </div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <span *ngIf="formitem.key == 'fulladdress' && addressloading">
          <fa-icon [icon]="icons.faspinner" [spin]="true" class=""></fa-icon>
        </span>
        <span *ngIf="formitem.value.type == 'display'">{{ formitem.value.value }}</span>
        <span *ngIf="formitem.value.error != ''" class="w3-small w3-text-red">{{ 'error.'+formitem.value.error | translate }}</span>
        <input
          *ngIf       = "formitem.value.type != 'select' && formitem.value.type != 'display'"
          class       = "w3-input w3-border"
          type        = "{{ formitem.value.type }}"
          [(ngModel)] = "formitem.value.value"
          (change)    = "checkinput(formitem.key, formitem.value)"
          [class]     = "formitem.value.error != '' ? 'w3-border-red w3-text-red' : ''"
          (keyup)     = "keyup(formitem.key, formitem.value.value)"
        />
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <button class="w3-button w3-border w3-margin-right" type="back" (click)="back()">{{ 'button.back' | translate | initcase }}</button>
        <button class="w3-button w3-border" type="submit" (click)="submit()" [disabled]="checkrequiredfields() ? 'disabled' : ''">{{ 'button.next' | translate | initcase }} </button>
      </div>
    </div>
  </div>
</div>
