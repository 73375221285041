export interface FormItem {
  type: string,
  required?: boolean,
  visible?: boolean,
  value: string,
  price?: number,
  error?: string,
  defaultvalue?: string
};

export interface FormItems {
  initials: FormItem,
  middlename: FormItem
  lastname: FormItem
  dateofbirth: FormItem
  country: FormItem
  zipcode: FormItem
  street: FormItem
  housenumber: FormItem
  housenumber_suffix: FormItem
  city: FormItem
  fulladdress: FormItem
  phonenumber: FormItem
  emailaddress: FormItem
  membertype: FormItem
}
