import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService }       from '@omnilnp/services/data.service';
import { IconsService }      from '@omnilnp/services/icons.service';
import { PaymentService }    from '@omnilnp/services/payment.service';
import { RegisterService }   from '@omnilnp/services/register.service';
import { RouterService }     from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-overview',
  templateUrl: './overview.component.html',
})
export class OverviewComponent implements OnInit {

  constructor ( public data:     DataService
              , public icons:    IconsService
              , public payment:  PaymentService
              , public register: RegisterService
              , public router:   RouterService
              ) {}

  paid:   boolean = false;
  amount: string  = '0';

  @ViewChild('ingenicoForm') ingenicoForm : ElementRef;

  ngOnInit( ) {
    this.initialize();
  }

  async initialize() {
    this.router.showwait = true;
    this.register.formitems.fulladdress.type = 'display';
    await this.payment.initialize();
    this.router.showwait = false;
  }

  orderasis(a: any, b: any) {
    return 1;
  }

  async back() {
    await this.router.navigateto('register');
  }

  getdisplayvalue( key, value ) {
    if ( typeof value.options == 'undefined' ) {
      return value.value;
    } else {
      if ( key == 'membertype' ) {
        if ( typeof value.options.free.options[value.value] != 'undefined' ) {
          return value.options.free.options[value.value]['value'];
        } else {
          return value.options.paid.options[value.value]['value'];
        }

        return 'invalid value'
      }

      if ( key == 'country' ) {
        return value.options.countries.options[value.value]['value'];
      }

      if ( key == 'pickup_location' ) {
        return value.options.locations.options[value.value]['value'];
      }
    }
  }

  getoptiontext( membertype ) {
    if ( membertype != undefined
         && membertype.value   != undefined
         && membertype.options != undefined
         && membertype.options.free != undefined
         && membertype.options.paid != undefined
      ) {
      if ( membertype.options.free[membertype.value] != undefined ) {
        return membertype.options.free[membertype.value].info;
      } else if ( membertype.options.paid[membertype.value] != undefined ) {
        return membertype.options.paid[membertype.value].info;
      }
    }

    return '';
  }

  async submit( ) {
    let memberType = await this.register.getBorrowerType(this.register.formitems.membertype.value);
    if ( memberType != undefined 
          && memberType['code'] != undefined
          && memberType['priceInEur'] == undefined) {

      await this.register.save(false);
      this.router.navigateto('result');
    } else {
      console.log(this.payment.ingenico);
      await this.data.saveTempLibMember( this.payment.ingenico['ORDERID'] );
      let form = this.ingenicoForm.nativeElement;
      form.submit();
    }

    // if ( this.register.formitems.membertype != undefined
    //      && this.register.formitems.membertype.value != undefined
    //      && this.register.formitems.membertype.price != undefined
    //      && this.register.formitems.membertype.price == 0
    //    ) {
    //   await this.register.save(false);
    //   this.router.navigateto('result');
    // } else {
    //   await this.data.savedata( this.payment.ingenico['ORDERID'] );
    //   let form = this.ingenicoForm.nativeElement;
    //   form.submit();
    // }
  }

}
