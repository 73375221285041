import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { DataService }       from '@omnilnp/services/data.service';
import { IconsService }      from '@omnilnp/services/icons.service';
import { ListService }       from '@omnilib/services/list.service';
import { PaymentService }    from '@omnilnp/services/payment.service';
import { RenewalService }    from '@omnilnp/services/renewal.service';
import { RouterService }     from '@omnilnp/services/router.service';

@Component({
  selector: 'lenerspas-renewal',
  templateUrl: './renewal.component.html',
})
export class RenewalComponent implements OnInit {

  @ViewChild('ingenicoForm') ingenicoForm : ElementRef;

  free: boolean = false;

  constructor ( public data:    DataService
              , public icons:   IconsService
              , public list:    ListService
              , public payment: PaymentService
              , public renewal: RenewalService
              , public route:   ActivatedRoute
              , public router:  RouterService
              ) {}

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this.router.showwait = true;
    this.router.title = 'renewal';

    const params      = this.route.params;
    const queryparams = this.route.queryParams;

    let id = await decodeURIComponent(params['value']['token1'] );

    if ( params['value']['token1'] ) {
      await this.renewal.getBorrower(id);

      if ( !this.router.success ) {
        this.router.navigateto('result');
      } else {
        this.free = this.list.getValue( this.renewal.borrower, 'Price_in_eur', false ) == '0';

        if ( !this.free ) {
          await this.payment.initialize();
        }
      }
    } else {
      this.router.success = false;
      this.router.message = 'failed_member_found';
      this.router.navigateto('result');
    }

    this.router.showwait = false;
  }

  async submit( ) {
    if ( this.list.getValue( this.renewal.borrower, 'Price_in_eur', false ) == '0' ) {
      this.renewal.save( true );
      this.router.navigateto('result');
    } else {
      await this.data.saverenewaldata( this.payment.ingenico['ORDERID'], this.renewal.borrower );
      let form = this.ingenicoForm.nativeElement;
      form.submit();
    }
  }
}
