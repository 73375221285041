import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import CryptoES from 'crypto-es';
import { format, toDate  } from 'date-fns';
import { environment } from '@omnienvironments/omnicms/devcms/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  secretKey: string = `5A7234743777217A25432A462D4A614E`;
  headers: HttpHeaders = new HttpHeaders;

  passwordLength = 32;

  constructor( public http: HttpClient ) {}

  async setHeader() {
    this.headers.set('Access-Control-Allow-Origin', '*');
  }

  async shasign( shastring ) {
    return CryptoES.SHA1( shastring ).toString().toUpperCase();
  }

  async encryptUri ( $string ) {
    return encodeURIComponent(CryptoES.SHA1('SALT1:'+$string+':SALT2:'+ format(toDate(new Date()),'yyyyMMdd')).toString());
  }

  async hash ( password ) {
    if ( environment.apiurl != '' ) {
      this.setHeader();
      let url        = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'what' : 'hash', 'password' : password };
      let params     = await this.encrypt( JSON.stringify ( jsonParams ) );
      let result     = await this.http.post( url, { content: params }, { headers: this.headers, responseType: 'text' } ).toPromise();

      return await this.decrypt ( result );
    } else {
      return null;
    }
  }

  async compare ( password, password_hash ) {
    if ( environment.apiurl != '' ) {
      this.setHeader();
      let url = environment.production ? environment.apiurl : 'wsdl';
      let jsonParams = { 'what' : 'compare', 'password' : password, 'password_hash': password_hash };
      let params = await this.encrypt( JSON.stringify ( jsonParams ) );
      let result = await this.http.post( url, { content: params }, { headers: this.headers, responseType: 'text' } ).toPromise();
      return await this.decrypt ( result ) == 'true';
    } else {
      return false;
    }
  }

  async encryptToApi ( $string ) {
    return await CryptoES.AES.encrypt($string.toString(CryptoES.enc.Utf8), this.secretKey).toString();
  }

  async decryptFromApi ( $string ) {
    let bytes  = await CryptoES.AES.decrypt($string.toString(), this.secretKey);
    return await bytes.toString(CryptoES.enc.Utf8);
  }

  getrandomkey() {
    let chars          = "0123456789abcdef";
    let password       = "";

    for (let i = 0; i < this.passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length );
      password += chars.substring(randomNumber, randomNumber+1);
    }

    return password;
  }

  async scramble( $encrypted, $key1, $key2 ) {
    let bigkey    = '';
    let maxlength = Math.max( $encrypted.length, $key1.length, $key2.length );
    for ( let i = 0; i < maxlength; i++ ) {
      if ( i < $key1.length ) {
        bigkey += $key1.substring(i,i+1);
      }
      if ( i < $encrypted.length ) {
        bigkey += $encrypted.substring(i,i+1);
      }
      if ( i < $key2.length ) {
        bigkey += $key2.substring(i,i+1);
      }
    }
    return bigkey.split('').reverse().join('');
  }

  async unscramble( $string ) {
    let string = $string.split('').reverse().join('');
    let key = { key1 : ''
              , key2 : ''
              , encrypted : ''
              };

    let lengthencrypted = string.length - ( this.passwordLength * 2 );

    while ( string.length > 0 ) {
      if ( key.key1.length < this.passwordLength ) {
        key.key1 += string.substring(0,1);
        string = string.substring(1);
      }
      if ( key.encrypted.length < lengthencrypted ) {
        key.encrypted += string.substring(0,1);
        string = string.substring(1);
      }
      if ( key.key2.length < this.passwordLength ) {
        key.key2 += string.substring(0,1);
        string = string.substring(1);
      }
    }

    return key;
  }

  async encrypt ( $string, force = false ) {
    if ( environment.encrypt || force ) {
      try {
        let key1 = await this.getrandomkey();
        let key2 = await this.getrandomkey();
        let encrypted = await this.scramble( CryptoES.AES.encrypt( JSON.stringify($string), CryptoES.enc.Hex.parse(key1), {iv: CryptoES.enc.Hex.parse(key2), padding: CryptoES.pad.ZeroPadding}).toString(), key1, key2);
        return await encodeURIComponent( encrypted );
      } catch(e) {
        return null;
      }
    } else {
      return $string;
    }
  }

  async decrypt ( $encrypted, force = false ) {
    if ( environment.encrypt || force ) {
      let key = await this.unscramble ( decodeURIComponent ( $encrypted ).replace(/[\u0000-\u0019]+/g, '') );
      try {
        return CryptoES.AES.decrypt(key.encrypted.replace(/[\u0000-\u0019]+/g, ''), CryptoES.enc.Hex.parse(key.key1), {iv:CryptoES.enc.Hex.parse(key.key2), padding: CryptoES.pad.ZeroPadding}).toString(CryptoES.enc.Utf8).replace(/[\u0000-\u0019]+/g, '');
      } catch(e) {
        return null;
      }
    } else {
      return $encrypted;
    }
  }

  async encryptinphp ( string ) {
    if ( environment.apitype == 'php' ) {
      this.setHeader();
      let params = encodeURIComponent ( JSON.stringify ( { 'what' : 'encrypt', 'string' : string } ) );
      return await this.http.post( environment.apiurl, { content: params }, { headers: this.headers, responseType: 'text' } ).toPromise();
    } else {
      return null;
    }
  }

  async decryptinphp ( string ) {
    if ( environment.apitype == 'php' ) {
      this.setHeader();
      let params = encodeURIComponent ( JSON.stringify ( { 'what' : 'decrypt', 'string' : string } ) );
      return await this.http.post( environment.apiurl, { content: params }, { headers: this.headers, responseType: 'text' } ).toPromise();
    } else {
      return null;
    }
  }


}
