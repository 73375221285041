<div class="w3-white">
  <div class="w3-content w3-display-container paddingtop">
    <img class="" src="assets/images/header.png" />
    <div class="w3-display-bottomright">
      <span
        class="link w3-margin-right"
        (click)="router.navigateto('register')"
        >{{ 'register.name' | translate | initcase }}</span
      >
      <span
        *ngIf="language.currentLanguage == 'nl'"
        class="link"
        (click)="language.changeLanguage('en')"
        >{{ 'language.en' | translate | initcase }}</span
      >
      <span
        *ngIf="language.currentLanguage == 'en'"
        class="link"
        (click)="language.changeLanguage('nl')"
        >{{ 'language.nl' | translate | initcase }}</span
      >
      
    </div>
  </div>
  <div class="headerbar w3-padding">
    <div class="w3-content">
      <!--
      <a
        class="link w3-margin-left"
        href="http://www.uva.nl/{{ language.currentLanguage }}/home"
        target="_blank"
        ><fa-icon [icon]="icons.faleft"></fa-icon>&nbsp;UvA Homepage</a
      >
      -->
      &nbsp;
    </div>
  </div>
</div>
<div class="headerbar2 w3-padding w3-margin-bottom">
  <div class="w3-content">
    <a class="link w3-margin-left" href="http://uba.uva.nl/" target="_blank"
      >Home</a
    >
  </div>
</div>
<br/>
