import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@omnilnp/services/http.loader.factory';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from '@omnilnp/modules/header/header.component';
import { RegisterComponent } from '@omnilnp/modules/register/register.component';
import { RenewalComponent } from '@omnilnp/modules/renewal/renewal.component';
import { PaymentComponent } from '@omnilnp/modules/payment/payment.component';
import { ResultComponent } from '@omnilnp/modules/result/result.component';
import { DutchAddressComponent } from '@omnilnp/modules/dutchaddress/dutchaddress.component';
import { OverviewComponent } from '@omnilnp/modules/overview/overview.component';
import { InitCase, FilterHidden, FilterDisplay, SortCountries } from '@omnilnp/services/filter';
import { RecaptchaModule } from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RegisterComponent,
    RenewalComponent,
    PaymentComponent,
    DutchAddressComponent,
    OverviewComponent,
    InitCase,
    FilterHidden,
    FilterDisplay,
    SortCountries,
    ResultComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
