import { Injectable }          from '@angular/core';
import { DataService }         from '@omnilib/services/data.service';
import { LanguageService }     from '@omnilnp/services/language.service';
import { ListService }         from '@omnilib/services/list.service';
import { FormItems, FormItem } from '@omnilnp/interfaces/formitems.interface';
import { RouterService }       from '@omnilnp/services/router.service';
import { format, parseISO, toDate  }  from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  formitems: FormItems = {
    initials: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    middlename: { type: 'text', required: false, value: '', error: '', defaultvalue: '' },
    lastname: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    dateofbirth: { type: 'date', required: true, value: '', error: '', defaultvalue: '' },
    country: {
      type: 'select',
      required: true,
      value: 'NLD',
      error: '',
      defaultvalue: 'NLD'
    },
    zipcode: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    street: { type: 'hidden', required: false, visible: false, value: '', error: '', defaultvalue: '' },
    housenumber: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    housenumber_suffix: { type: 'text', required: false, value: '', error: '', defaultvalue: '' },
    city: { type: 'hidden', required: false, visible: false, value: '', error: '', defaultvalue: '' },
    fulladdress: { type: 'display', required: false, visible: false, value: '', error: '', defaultvalue: '' },
    phonenumber: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    emailaddress: { type: 'email', required: true, value: '', error: '', defaultvalue: '' },
    membertype: {
      type: 'select',
      required: true,
      value: '11',
      error: '',
      defaultvalue: '11'
    }
  };

  dutchaddress = {
    zipcode: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    street: { type: 'hidden', required: false, visible: false, value: '', error: '', defaultvalue: '' },
    housenumber: { type: 'text', required: true, value: '', error: '', defaultvalue: '' },
    housenumber_suffix: { type: 'text', required: false, value: '', error: '', defaultvalue: '' },
    city: { type: 'hidden', required: false, visible: false, value: '', error: '', defaultvalue: '' },
    fulladdress: { type: 'display', required: false, visible: false, value: '', error: '', defaultvalue: '' },
  };

  constructor ( public data: DataService
              , public language: LanguageService
              , public list: ListService
              , public router: RouterService
              ) {}

  initialize() {
    for ( let item in this.formitems ) {
      this.formitems[item].value = this.formitems[item].defaultvalue;
      this.formitems[item].error = '';
    }

    for ( let item in this.dutchaddress ) {
      this.dutchaddress[item].value = this.dutchaddress[item].defaultvalue;
      this.dutchaddress[item].error = '';
    }
  }

  async searchAddress( $formitems ) {
    $formitems.street.value      = '';
    $formitems.city.value        = '';
    $formitems.fulladdress.value = '';
    $formitems.fulladdress.error = '';

    let postalcode        = $formitems.zipcode.value.replace(' ', '');
    let districtcode      = postalcode.substr(0,4);
    let lettercombination = postalcode.substr(4,2);

    let address = await this.data.getpc( $formitems.zipcode.value.replace(' ', ''), $formitems.housenumber.value );
    console.log(address['result']['resultset']);
    address = address['result']['resultset'];
    if ( this.list.getValue( address, 'addressType' ) == undefined ) {
      $formitems.street.value      = '';
      $formitems.city.value        = '';
      $formitems.fulladdress.value = '';
      $formitems.fulladdress.error = 'noaddressfound';
    } else {
      $formitems.street.value      = this.list.getValue( address, 'street', true );
      $formitems.city.value        = this.list.getValue( address, 'city', true );
      $formitems.fulladdress.value = $formitems.street.value
                                   + ' '
                                   + $formitems.housenumber.value
                                   + ( $formitems.housenumber_suffix.value != '' ? ' ' + $formitems.housenumber_suffix.value : '' )
                                   + ', '
                                   + districtcode
                                   + ' '
                                   + lettercombination
                                   + '  '
                                   + $formitems.city.value
                                   ;
      $formitems.fulladdress.error = '';
    }
  }

  async getBorrowerType(borrowerTypeId) {
    let languageCode = '';
    if(this.language.currentLanguage == 'nl') {
      languageCode = 'ned';
    }
    if(this.language.currentLanguage == 'en') {
      languageCode = 'eng';
    }
    let result = await this.data.callapi( 'get', 'borrow-types/' + borrowerTypeId + "/language/" + languageCode, null);
    return result['result']['resultset'];
  }

  async checkuniquelibmember() {

    let params = { "initials"    : this.formitems.initials.value
                 , "lastName"    : this.formitems.lastname.value
                 , "middleName"  : this.formitems.middlename.value
                 , "dateOfBirth" : format(parseISO(this.formitems.dateofbirth.value+'T00:00:00'),'yyyy-MM-dd' )
               };

    let result = await this.data.callapi ( 'post', 'full-person/check-if-unique', params );

    if( result['result']['code'] ) {
        if( result['result']['code'] == '0') {
            return true;
        }
    }


    /*
    let result = await this.operation.executeOperation( 'operation', jsonParams );

    if ( result['Result']['Code'] ) {
      if ( result['Result']['Code'] == '0' ) {
        try {
          return result['Result']['ResultSets']['libMemberExists']['Value'] == "FALSE";
        } catch(e) {
          console.log (e);
        }
      }
    }

    */
    return false;
  }

  async save( paid: boolean = false ) {


    let params = { 'initials'        : this.formitems.initials.value
              , 'lastNamePrefix'     :    this.formitems.middlename.value
              ,  'lastName'          : this.formitems.lastname.value
              ,  'dateOfBirth'       : format(parseISO(this.formitems.dateofbirth.value+'T00:00:00'),'dd-MM-yyyy')
              ,  'streetName'        : this.formitems.street.value
              ,  'houseNumber'       : this.formitems.housenumber.value
              ,  'houseNumberSuffix' : this.formitems.housenumber_suffix.value
              ,  'postalCode'        : this.formitems.zipcode.value
              ,  'city'              : this.formitems.city.value
              ,  'gender'            : 'o'
              ,  'email'             : this.formitems.emailaddress.value
              ,  'entity'            : 'UVA'
              ,  'location'          : 'BIB01'
              ,  'country'           : this.formitems.country.value
              ,  'language'          : this.language.currentLanguage == 'nl' ? 'NED' : 'ENG'
              ,  'phoneNumber'       : this.formitems.phonenumber.value
              ,  'streetNameDutch'       : this.dutchaddress.street.value
              ,  'houseNumberDutch'      : this.dutchaddress.housenumber.value
              ,  'houseNumberSuffixDutch': this.dutchaddress.housenumber_suffix.value
              ,  'postalCodeDutch'       : this.dutchaddress.zipcode.value
              ,  'cityDutch'             : this.dutchaddress.city.value
              ,  'borrowerType'      : this.formitems.membertype.value
    };

    let result = await this.data.callapi ( 'put', 'full-person/import', params );

    if (result['result']['code']) {
      if (result['result']['code'] == '0') {
        this.router.success = true;
        this.router.message = 'success_register_text';
      } else {
        this.router.success = false;
        this.router.message = paid ? 'failed_payment_text' : 'failed_register_text';
      }
    } else {
      this.router.success = false;
      this.router.message = paid ? 'failed_payment_text' : 'failed_register_text';
    }

    this.initialize();
  }
}
