<lenerspas-header></lenerspas-header>
<div *ngIf="router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top w3-center">
  <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
</div>
<div *ngIf="!router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top">
  <div class="w3-container w3-margin-bottom w3-responsive">
    <h2 class="w3-margin-bottom">
      {{ 'register.title' | translate | initcase }}
    </h2>
    <hr class="w3-border-dark-grey" />
    <p>{{ 'register.line1' | translate | initcase }}</p>
    <!--
    <p>{{ 'register.ul1_line' | translate | initcase }}</p>
    <ul>
      <li>{{ 'register.ul1_li1' | translate | initcase }}</li>
      <li>{{ 'register.ul1_li2' | translate | initcase }}</li>
    </ul>
    <p>{{ 'register.ul2_line' | translate | initcase }}</p>
    <ul>
      <li>{{ 'register.ul2_li1' | translate | initcase }}</li>
      <li>{{ 'register.ul2_li2' | translate | initcase }}</li>
    </ul>
    -->
    <p>
      {{ 'register.line2_1' | translate | initcase
      }}<a href="{{ 'register.line2_link' | translate }}">{{
        'register.line2_2' | translate
      }}</a
      >{{ 'register.line2_3' | translate }}
    </p>
    <p>
      {{ 'register.line3_1' | translate | initcase
      }}<a href="{{ 'register.line3_link' | translate }}">{{
        'register.line3_2' | translate
      }}</a
      >{{ 'register.line3_3' | translate }}
    </p>
    <hr class="w3-border-dark-grey" />
  </div>
  <div class="w3-container w3-margin-bottom w3-responsive">
    <div
      class="w3-cell-row w3-margin-bottom"
      *ngFor="let formitem of register.formitems | keyvalue: orderasis"
    >
      <div class="w3-cell w3-mobile w3-cell-middle col40" *ngIf="formitem.value.type != 'hidden'">
        <span>{{ 'formitems.' + formitem.key | translate | initcase }}</span><span *ngIf="formitem.value.required">*</span>
      </div>
      <div class="w3-cell w3-mobile w3-cell-middle col60" *ngIf="formitem.value.type != 'hidden'">
        <span *ngIf="formitem.key == 'fulladdress' && addressloading">
          <fa-icon [icon]="icons.faspinner" [spin]="true" class=""></fa-icon>
        </span>
        <span *ngIf="formitem.value.type == 'display'">{{ formitem.value.value | translate | initcase  }}</span>
        <span *ngIf="formitem.value.error != ''" class="w3-small w3-text-red">{{ 'error.'+formitem.value.error | translate }}</span>
        <input
          *ngIf       = "formitem.value.type != 'select' && formitem.value.type != 'display'"
          class       = "w3-input w3-border"
          type        = "{{ formitem.value.type }}"
          [(ngModel)] = "formitem.value.value"
          (change)    = "checkinput(formitem.key, formitem.value.value)"
          [class]     = "formitem.value.error != '' ? 'w3-border-red w3-text-red' : ''"
          (keyup)     = "keyup($event, formitem.key, formitem.value.value)"
        />
        <select
          *ngIf       = "formitem.key == 'country'"
          class       = "w3-select w3-border"
          [(ngModel)] = "formitem.value.value"
          (change)    = "checkinput(formitem.key, formitem.value.value)"
          [class]     = "formitem.value.error != '' ? 'w3-border-red w3-text-red' : ''"
        >
          <option
            *ngFor = "let option of router.countries | keyvalue | sortcountries"
            value = "{{ option.key }}"
          >{{ list.getValue( option.value, language.currentLanguage, true) }}
          </option>
        </select>
        <select
          *ngIf       = "formitem.key == 'membertype'"
          class       = "w3-select w3-border"
          [(ngModel)] = "formitem.value.value"
          (change)    = "checkinput(formitem.key, formitem.value.value)"
          [class]     = "formitem.value.error != '' ? 'w3-border-red w3-text-red' : ''"
        >
          <optgroup
            class  = "w3-margin-bottom"
            *ngFor = "let optgroup of router.membertypes; index as i"
            label  = "{{ 'formitems.'+router.membertypes[i]['label'] | translate }}"
          >
            <option
              *ngFor = "let option of router.membertypes[i]['options']; index as j"
              value = "{{ router.membertypes[i]['options'][j]['code'] }}"
            >{{ 'membertype.'+router.membertypes[i]['options'][j]['code'] | translate }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        {{ ( register.formitems.membertype.value == "78" ? 'membertype.info2' : 'membertype.info' ) | translate }}
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <input type="checkbox" class="w3-check" [(ngModel)]="checked" />&nbsp;&nbsp;<label
          >{{ 'register.check_line1' | translate | initcase
          }}<a href="{{ 'register.check_link' | translate }}">{{
            'register.check_line2' | translate
          }}</a
          >{{ 'register.check_line3' | translate }}</label
        >
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <re-captcha (resolved)="resolved($event)" (error)="errored($event)" errorMode="handled" siteKey="{{ this.router.config.recaptcha.sitekey }}"></re-captcha>
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <button class="w3-button w3-border" type="submit" (click)="submit()" [disabled]="notarobot && !addressloading && checked && !checkrequiredfields() ? '' : 'disabled'">{{ 'button.next' | translate | initcase }} </button>
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60 italic">
        {{ 'register.marked' | translate | initcase }}
      </div>
    </div>
  </div>
</div>
