import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RegisterComponent } from '@omnilnp/modules/register/register.component';
import { DutchAddressComponent } from '@omnilnp/modules/dutchaddress/dutchaddress.component';
import { OverviewComponent } from '@omnilnp/modules/overview/overview.component';
import { PaymentComponent } from '@omnilnp/modules/payment/payment.component';
import { ResultComponent } from '@omnilnp/modules/result/result.component';
import { RenewalComponent } from '@omnilnp/modules/renewal/renewal.component';

const routes: Routes = [
//   { path: 'renewal',                 component: RenewalComponent },
//   { path: 'renewal/:token1',         component: RenewalComponent },
  { path: 'register',                component: RegisterComponent },
  { path: 'dutchaddress',            component: DutchAddressComponent },
  { path: 'overview',                component: OverviewComponent },
  { path: 'payment',                 component: PaymentComponent },
  { path: 'payment/:token1/:token2', component: PaymentComponent },
  { path: 'payment/:token1',         component: PaymentComponent },
  { path: 'result',                  component: ResultComponent },
  { path: '', redirectTo: '/register', pathMatch: 'full' },
  { path: '**',                      component: RegisterComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
