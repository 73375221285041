<lenerspas-header></lenerspas-header>
<div *ngIf="router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top w3-center">
  <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
</div>
<div *ngIf="!router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top">
  <div class="w3-container w3-margin-bottom w3-responsive">
    <h2 class="w3-margin-bottom">
      {{ 'renewal.title' | translate | initcase }}
    </h2>
    <hr class="w3-border-dark-grey" />
    <p *ngIf="free">{{ 'renewal.free' | translate | initcase }}</p>
    <p *ngIf="!free">{{ 'renewal.paid' | translate: { amount: list.getValue( this.renewal.borrower, 'Price_in_eur', false ) } | initcase }}</p>
    <p>{{ 'renewal.line1' | translate | initcase }}</p>
    <button class="w3-button w3-border" type="submit" (click)="submit()">{{ 'button.submit' | translate | initcase }}</button>
    <form *ngIf="!free" #ingenicoForm id="ingenicoForm" name="ingenicoForm" action="{{ payment.url }}" method="post">
      <input *ngFor="let input of payment.ingenico | keyvalue" type="hidden" name="{{ input.key }}" value="{{ input.value }}">
    </form>
  </div>
</div>
