<lenerspas-header></lenerspas-header>
<div *ngIf="router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top w3-center">
  <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
</div>
<div *ngIf="!router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top">
  <div class="w3-container w3-margin-bottom w3-responsive">
    <h2 class="w3-margin-bottom">
      {{ 'register.title' | translate | initcase }}
    </h2>
    <hr class="w3-border-dark-grey" />
    <p>{{ 'register.overview' | translate | initcase }}</p>
    <hr class="w3-border-dark-grey" />
  </div>
  <div class="w3-container w3-margin-bottom w3-responsive">
    <div class="w3-cell-row w3-margin-bottom" *ngFor="let formitem of register.formitems | keyvalue: orderasis | filterdisplay">
      <div class="w3-cell w3-mobile w3-cell-middle col40">
        {{ 'formitems.' + formitem.key | translate | initcase }}
      </div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <span>{{ getdisplayvalue(formitem.key, formitem.value) }}</span>
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">&nbsp;</div>
    </div>
  </div>
  <div class="w3-container w3-margin-bottom w3-responsive" *ngIf="register.formitems.country.value != 'NLD'">
    <div class="w3-cell-row w3-margin-bottom bold">
      {{ 'register.dutchaddress' | translate | initcase }}
    </div>
    <div class="w3-cell-row w3-margin-bottom" *ngFor="let formitem of register.dutchaddress | keyvalue: orderasis | filterdisplay">
      <div class="w3-cell w3-mobile w3-cell-middle col40">
        {{ 'formitems.' + formitem.key | translate | initcase }}
      </div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        <span>{{ getdisplayvalue(formitem.key, formitem.value) }}</span>
      </div>
    </div>
  </div>
  <div class="w3-container w3-margin-bottom w3-responsive">
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col60">
        {{ getoptiontext( register.formitems.membertype ) }}
      </div>
    </div>
    <div class="w3-cell-row w3-margin-bottom">
      <div class="w3-cell w3-mobile w3-cell-middle col40">&nbsp;</div>
      <div class="w3-cell w3-mobile w3-cell-middle col6">
        <button class="w3-button w3-border w3-margin-right" type="back" (click)="back()">{{ 'button.back' | translate | initcase }}</button>
        <button class="w3-button w3-border" type="submit" (click)="submit()">{{ 'button.submit' | translate | initcase }}</button>
      </div>
    </div>
  </div>
  <form #ingenicoForm id="ingenicoForm" name="ingenicoForm" action="{{ payment.url }}" method="post">
    <input *ngFor="let input of payment.ingenico | keyvalue" type="hidden" name="{{ input.key }}" value="{{ input.value }}">
  </form>
</div>
