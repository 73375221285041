export const environment = {
  production: true,
  acc: false,
  ssl: true,
  apiurl: 'https://hvarest.omnicms3.nl:9003/api/v1/',
  apiurlWsdl: 'https://www.lenerspas.nl/soap/',
  apitype: 'php',
  encrypt: false,
  config: {
    product: "Lenerspas",
    customer_fullname: "Universiteit van Amsterdam",
    customer_shortname: "UVA",
    languages:              [ { "id": 1, "LanguageCode": "nl", "LanguageName" : "Nederlands" }
                            , { "id": 2, "LanguageCode": "en", "LanguageName" : "English" }
                            ],
    ingenico: {
       url: "https://secure.ogone.com/ncol/prod/orderstandard.asp",
       orderid: "c.4302.0001",
       pspid: "BIBiDeal",
       sha_in_key: "ie!wufbw98234#342@234h238",
       sha_out_key: "@DJ@d32283d0j1j2233@39+",
       currency: "EUR",
       bgcolor: "#F3F3F3",
       txtcolor: "#004C93",
       tblbgcolor: "#FFFFFF",
       tbltxtcolor: "#666666",
       buttonbgcolor: "#004C93",
       buttontxtcolor: "#FFFFFF",
       fonttype: "Verdana",
       pm: "iDEAL",
       homeurl: "https://www.lenerspas.nl/site/payment",
       accepturl: "https://www.lenerspas.nl/site/payment/success",
       declineurl: "https://www.lenerspas.nl/site/payment/decline",
       exceptionurl: "https://www.lenerspas.nl/site/payment/fail",
       cancelurl: "https://www.lenerspas.nl/site/payment/cancel"
     },
    postcode: {
      url: "https://api.postcode.eu/nl/v1/addresses/postcode/",
      key: "KkEJQt53Wf61Q0KbmAJosoLi8c5QBqDjbigRAE7cULo",
      secret: "1nFvlz5iTPaVM64nUxcBaCVQpj7sH2utCPGTrCcZDMdpfThnyE"
    },
    countries: {
      url: "https://restcountries.com/v3.1/all?fields=name,cca2,cca3,translations",
      key: "516|Yvq8RPCoJIi657knKMakJFlTfeG3BXpMEArwh8vN"
    },
    recaptcha : {
      sitekey : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
      secretkey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
    },
    recaptchaprod : {
      sitekey : "6LdRZ6cgAAAAAIEt53eDJnbM_fnDXucB7p-OVwxE",
      secretkey: "6LdRZ6cgAAAAAP9tQNm5XTHf7Q0lCHDvyX__Camf"
    }
  }
};
