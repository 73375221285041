import { Injectable } from '@angular/core';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  faleft = faAngleLeft;
  faspinner = faSpinner;
}
