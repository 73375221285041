<lenerspas-header></lenerspas-header>
<div *ngIf="router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top w3-center">
  <fa-icon [icon]="icons.faspinner" [spin]="true" class="w3-jumbo"></fa-icon>
</div>
<div *ngIf="!router.showwait" class="w3-content w3-container w3-white w3-margin-bottom w3-margin-top">
  <div class="w3-container w3-margin-bottom w3-responsive">
    <h2 class="w3-margin-bottom">{{ ( router.success ? 'result.success' : 'result.failed' ) + '_' + router.title | translate | initcase }}</h2>
    <hr class="w3-border-dark-grey" />
    <p>{{ 'result.'+router.message | translate: { date: list.getValue( this.renewal.borrower, 'ValidUntil', false ) }  | initcase }}</p>
  </div>
</div>
